<template>
  <v-container class="mt-5 mb-15 px-10" fluid>
    <PageHeader
      :header-text="plugin ? 'Update ' : 'Install' + ' ClickSend plugin'"
    >
      <template #subheader>
        This plugin allows sending SMS messages from a ClickSend Campaign and saves SMS actions.
      </template>
    </PageHeader>

    <div class="mt-10">
      <validation-observer
        ref="inputRef"
      >
        <div>
          <CustomTextInput
            v-model="userName"
            header="User Name"
            required
          />
        </div>
        <div>
          <CustomTextInput
            v-model="apiKey"
            header="API Key"
            :maxlength="150"
            required
          />
        </div>
        <template v-if="plugin">
          <v-alert
            v-if="credentialsChanged"
            type="error"
            class="mt-2"
            style="font-size: 12px;"
          >
            <strong>WARNING:</strong> By changing the credentials, you are changing the ClickSend account this plugin works with.<br>
            Please be aware that the following will happen if you save these changes all webhooks for this account will be removed from the old ClickSend account.
          </v-alert>
          <div>
            <CustomTextInput
              v-model="optOutKeywords"
              header="Opt Out Keywords"
              hint="This is a comma-separated list of opt-out keywords. When Pulse receives one of these keywords in a message, it will opt-out the contact's phone number from future SMS messages."
            />
            <CustomTextInput
              v-model="optInKeywords"
              header="Opt In Keywords"
              hint="This is a comma-separated list of opt-in keywords. When Pulse receives one of these keywords in a message, it will make sure the contact's phone number is subscribed to receive SMS messages."
            />
          </div>
        </template>
      </validation-observer>
      <v-row class="mt-12">
        <v-col cols="12" class="d-flex justify-end">
          <v-btn
            v-if="installed"
            class="custom-button custom-button--red-text px-8 mr-4"
            height="34px"
            depressed
            @click="deactivateDialog = true;"
          >
            Deactivate
          </v-btn>

          <v-btn
            class="custom-button custom-button--blue px-13"
            height="34px"
            depressed
            :block="$vuetify.breakpoint.xsOnly"
            @click="trigger"
          >
            {{ plugin ? 'Update' : 'Install' }}
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <DeactivateConfirmDialog
      v-if="deactivateDialog"
      :id="id"
      :plugin="plugin"
      @dismiss="deactivateDialog = false;"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import DeactivateConfirmDialog from "./components/DeactivateConfirmDialog";

export default {
  name: "AddPluginClickSend",
  metaInfo: {
    title: 'Install ClickSend'
  },
  components: {
    CustomTextInput,
    PageHeader,
    DeactivateConfirmDialog,
  },
  props: {
    id: {
      default: () => null,
      type: [Number, String],
    },
    installed: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    deactivateDialog: false,
    userName: null,
    apiKey: null,
    plugin: null,
    optInKeywords: null,
    optOutKeywords: null,
    oldUsername: null,
    oldApiKey: null,
  }),
  computed: {
    credentialsChanged() {
      return !!this.plugin && (this.oldUsername !== this.userName || this.oldApiKey !== this.apiKey);
    },
  },
  async created() {
    if (this.installed) {
      await this.loadAccountPlugin();
    }
  },
  methods: {
    async loadAccountPlugin() {
      const response = await this.$rest.plugins.get_resource(this.id, {
        includeCredentials: true,
      });
      if (!response.data) {
        return;
      }
      this.plugin = {...response.data};
      this.userName = this.plugin?.pluginConfiguration?.credentials?.username;
      this.apiKey = this.plugin?.pluginConfiguration?.credentials?.apiKey;
      this.oldUsername = this.userName;
      this.oldApiKey = this.apiKey;
      this.optOutKeywords = this.plugin?.pluginConfiguration?.optOutKeywords;
      this.optInKeywords = this.plugin?.pluginConfiguration?.optInKeywords;
    },
    trigger() {
      if ( this.userName === null || this.userName === '' ) {
        return this.$store.commit('snackbar/showMessage', {
          content: 'Username is required.',
          color: 'error',
        });
      }
      if ( this.apiKey === null || this.apiKey === '' ) {
        return this.$store.commit('snackbar/showMessage', {
          content: 'Api Key is required.',
          color: 'error',
        });
      }
      if (this.plugin) {
        return this.updatePlugin();
      } else {
        return this.installPlugin();
      }
    },
    async updatePlugin() {
      const pluginConfiguration = {
        credentials: {
          username: this.userName,
          apiKey: this.apiKey,
        },
        optOutKeywords: this.optOutKeywords,
        optInKeywords: this.optInKeywords,
      };

      const response = await this.$rest.plugins.put_resource(this.id, {
        ...this.plugin,
        plugin: this.plugin.plugin.id,
        pluginConfiguration
      });
      if (!response.data) {
        return this.$store.commit('snackbar/showMessage', {
          content: 'Failed to update plugin',
          color: 'error',
        });
      }

      this.$store.commit('snackbar/showMessage', {
        content: 'Plugin updated successfully',
        color: 'info',
      });
      setTimeout(() => {
        this.$router.push({
          name: "Plugins",
        })
      }, 500);
    },
    async installPlugin() {
      const pluginConfiguration = {
        credentials: {
          username: this.userName,
          apiKey: this.apiKey
        },
      };
      const response = await this.$rest.plugins.post_resource({
        plugin: +this.id,
        pluginConfiguration,
      });
      if (!response.data) {
        return this.$store.commit('snackbar/showMessage', {
          content: 'Failed to install plugin',
          color: 'error',
        });
      }
      this.$store.commit('snackbar/showMessage', {
        content: 'Installed plugin successfully',
        color: 'info',
      });
      setTimeout(() => {
        this.$router.push({
          name: "AddPluginClickSend",
          params: {
            id: response.data.id,
          },
          query: {
            installed: true,
          }
        });
      }, 500);
    },
  },
}
</script>

<style lang="scss" scoped>
  .delete-icon {
    cursor: pointer;
  }

.field-subheader {
  font-family: Open Sans,sans-serif;
  line-height: normal;
  color: #66788e;
  font-size: 13px;
}
</style>
